<template>
  <fki-sub-nav
    is-active
    :color="page.color"
    :items="items"
  />
  <fki-container
    fixed
    top="196px"
    left="0"
    width="100%"
    class="fki-company-page"
  >
    <fki-company :current-company="currentCompany" />
    <fki-footer is-active>
      <fki-footer-nav is-active />
    </fki-footer>
  </fki-container>
</template>

<script>
// @ is an alias to /src
import Api from '@/services/api'
import { FKISubNav, FKIFooterNav } from '@/components/FKINav'
import { FKIContainer } from '@/components/FKIContainer'
import { FKIFooter } from '@/components/FKIApp'
import { FKICompany } from '@/components/FKICompany'

export default {
  name: 'Company',
  components: {
    'fki-sub-nav': FKISubNav,
    'fki-container': FKIContainer,
    'fki-footer': FKIFooter,
    'fki-footer-nav': FKIFooterNav,
    'fki-company': FKICompany
  },
  props: {
    company: {
      type: String,
      default: 'fotokem'
    }
  },
  emits: ['isLoading', 'setBackgrounds'],
  data () {
    return {
      currentCompany: null,
      page: { color: 'yellow', body: null, slides: [] },
      facilities: {},
      items: []
    }
  },
  watch: {
    $route: 'checkParams'
  },
  async created () {
    if (this.$route.path === '/companies') {
      this.$router
        .push({
          path: '/companies/fotokem'
        })
        .catch(() => {})
    }
    await this.getPage()
    await this.getFacilities()
    this.getCompany()
  },
  methods: {
    async checkParams () {
      if (this.$route.path === '/companies') {
        this.$router
          .push({
            path: '/companies/fotokem'
          })
          .catch(() => {})
      }
      this.getCompany()
    },
    async getFacilities () {
      this.isLoading = true

      try {
        this.$emit('isLoading', true)
        const { data: result } = await Api().get('/facilities')
        this.items = []
        this.facilities = result
        // this.facilities.forEach(element => {
        //   element.slides.forEach(item => {
        //     item.src = item.src.replace('image/', '')
        //   })
        // })
        for (const key in result) {
          if (Object.hasOwnProperty.call(result, key)) {
            result[key].slides.forEach(item => {
              item.src = item.src.replace('image/', '')
            })
            this.items.push({
              slug: 'companies/' + result[key].slug,
              color: this.page.color,
              name: result[key].name
            })
          }
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
        this.$emit('isLoading', false)
      }
    },
    async getPage () {
      this.isLoading = true

      try {
        this.$emit('isLoading', true)
        const { data: result } = await Api().get('/page/s/companies')
        this.page = result
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
        this.$emit('isLoading', false)
      }
    },
    getCompany () {
      let slug = this.$route.params.company
      if (slug === 'margarita-mix-hollywood') slug = 'mmhw'
      if (slug === 'margarita-mix-santa-monica') slug = 'mmsm'
      this.currentCompany = this.facilities[slug]
      this.$emit('setBackgrounds', this.currentCompany.slides)
    }
  }
}
</script>
<style lang="scss" scoped>
  .fki-company-page {
    flex-direction: column;
    overflow-y: hidden !important;
    bottom:0;
  }
</style>
