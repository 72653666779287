<template>
  <div
    v-if="currentCompany"
    class="fki-about-wrapper"
  >
    <fki-container
      class="fki-about-box"
      color="rgba(255,255,255, .8)"
      column
    >
      <h2>{{ currentCompany.name }}</h2>
      <p
        v-if="currentCompany.linkName"
        class="about-link"
      >
        <a
          :href="currentCompany.link"
          target="_blank"
        >{{ currentCompany.linkName }}
        </a>
      </p>
      <span
        class="fki-about-content"
        v-html="currentCompany.body"
      />
    </fki-container>
  </div>
</template>

<script>
import { FKIContainer } from '../FKIContainer'
export default {
  name: 'FkiCompany',
  components: {
    'fki-container': FKIContainer
  },
  props: {
    currentCompany: {
      type: Object,
      default: undefined
    }
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.fki-about-wrapper {
    display: flex;
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    align-items: flex-start;
    flex-direction: column;
    transition: height .3s ease;
    margin-bottom:12px;
}
.fki-about-box {
  overflow-y: auto;
  padding: 25px 30px;
  min-height: 200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: height .3s ease;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.89);
  p {
    margin-top: 0;
    color: #000;
    text-align: center;
    line-height: 1.4;
    font-size: 2rem;
    width:100%;
    font-weight: 300;
    a {
      color: #000;
      text-align: center;
      text-decoration: none;
      line-height: 1.4;
      font-size: 2.4rem;
      width:100%;
      font-weight: 400;
    }
  }
  h2 {
    margin-top: 0;
    color: #000;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: .8rem;
    font-size: 3rem;
  }
  .fki-about-content {
    height:inherit;
    margin-top: 0;
    min-height: 100px;
    color: #000;
    text-align: left;
    line-height: 1.3;
    font-size: 1.8rem;
    width:100%;
    font-weight: 300;
  }
}
  @media screen and (min-width: 768px) {
    .fki-about-box {
      width:60%;
      margin: 7vh auto 10px;

    }
  }

</style>
<style lang="scss" module>
  span[style]{
    font: unset !important;
    color: unset !important;
    background: unset !important;
  }
  font[style]{
    font: unset !important;
    color: unset !important;
    background: unset !important;
  }
</style>
